import * as React from "react"
import appInsights from '../../../../AppInsights';
import HumanTalent from "../../../route/human_talent_en"





// markup
const IndexPage = (props) => {
  return (
    <React.Fragment>
      <HumanTalent props={props} />
      </React.Fragment>
  )
}

export default IndexPage
