import React, { Component } from "react";


import Layout from "../components/layou_en"
import Banner from "../components/others/banner"
import CardNews from "../components/others/cardNew_en"

import { StaticQuery, graphql } from "gatsby"
import Moment from "moment";
import en from "moment/locale/en-in";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

Moment.locale("en", en);
const objectGraphql = graphql`
{
  strapiTalentoHumano(locale: {eq: "en"}) {
    Banner {
      fondo {
        url
      }
      url {
        enlace
        titulo
        id
      }
      titulo
      id
    }
    descripcion {
      descripcion
      id
      img {
        img {
          url
        }
        id
      }
      titulo
    }
    Logros {
      titulo
      descripcion
      btn
      id
      pdf {
        url
      }
    }
  }
  allStrapiNoticias(
    filter: {locale: {eq: "en"}, Categorys: {nombre: {eq: "Human talent"}}}
  ) {
    edges {
      node {
        id
        titulo
         
        Tags {
          nombre
          id
        }
        Categorys {
         nombre
       }
        fecha
        min_descripcion
        descripcion
        imagen {
        url
      
        }
      }
    }
  }
  allStrapiHome(filter: {locale: {eq: "en"}}) {
    edges {
      node {
        Cantidad_noticias
      }
    }
  }
}

`


  
class HumanTalent extends Component {
  news = (data) => {
    let array = data.allStrapiNoticias.edges;
    let sortedArray = array.sort((a, b) => new Moment(a.node.fecha).format('YYYYMMDD') - new Moment(b.node.fecha).format('YYYYMMDD'))
    // let aNuevo = sortedArray.slice(sortedArray.length - (data.allStrapiHome.edges[0].node.Cantidad_noticias))
    let NewsortedArray = sortedArray.sort((a, b) => new Moment(b.node.fecha).format('YYYYMMDD') - new Moment(a.node.fecha).format('YYYYMMDD'))


    // let copyNewsortedArray= [...NewsortedArray];

    // let allArraypta=NewsortedArray.concat(copyNewsortedArray);
    // let allArrayptb=NewsortedArray.concat(allArraypta);

return NewsortedArray.map((item) => {
      return (
        <CardNews  props={this.props.props} data={item.node}  />
      )
    })
}

  render() {
var settings = {
          dots: true,
          infinite: false,
      speed: 100,
      slidesToShow: 3,
      slidesToScroll: 3,
      
   
      responsive: [
        
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ],
    };

    return (
      <StaticQuery
        query={objectGraphql}
        render={data => (
          <React.Fragment>
            <Layout props={this.props.props}>
              <Banner banner={data.strapiTalentoHumano.Banner} />
              <section className="padding-section-int">
                <div className="container">

                  <div className="row margin-b-60">
                    <div className="col-md-8 offset-md-2 text-center titulo-slim">
                      <h4>{data.strapiTalentoHumano.descripcion.titulo}</h4>
                      <p>                      {data.strapiTalentoHumano.descripcion.descripcion}
                      </p>
                      <div className="row">
                        {data.strapiTalentoHumano.descripcion.img.map((item) => {
                          return (
                            <div key={item.id} className="col-md-6"><img alt={item.img[0].url} className="img-fluid" src={item.img[0].url} /></div>
                          )
                        })}

                      </div>
                    </div>
                  </div>
                  {data.strapiTalentoHumano.Logros.map((item) => {
                    return (
                      <div key={item.id} className="row wow fadeIn margin-b-80" >
                        <div className="col-md-8 offset-md-2 text-center margin-b-20">
                          <h3>{item.titulo}</h3>
                          <p>{item.descripcion}</p>
                        </div>

                        <div className="col-md-12 text-center">
                          <p><a href={item.pdf.url} className="btn btn-primary">{item.btn}</a></p>
                        </div>
                      </div>
                    )
                  })}
                  <div className="row">
                    <div className="col-md-12 titulo-center text-center">
                      <h2>

                        Centinela News

                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div id="notas">
                           <Slider {...settings}>
                        {this.news(data)}
                        </Slider>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 titulo-center text-center">
                      <p>
                        <a href="/en/news" className="btn btn-primary">
                          <i className="fa fa-file-o"
                          ></i> See more news
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </Layout>
          </ React.Fragment>
        )}
      />
    )
  }
}

export default HumanTalent
